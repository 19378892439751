import { isEmpty } from 'typesafe-utils';

type Record = { id: number, name: string, inputLabel?:string }
type LoadFunction = (...parentIds: string[]) => Promise<Record[]>

const loadSelect = async (
  selectElementId: string,
  loadFunction: LoadFunction,
  ...loadFunctionArgs: string[]
) => {
  const select = document.getElementById(selectElementId) as HTMLSelectElement;
  const selectedValue = select.value;
  while (select.firstChild) {
    select.firstChild.remove();
  }
  if (loadFunctionArgs.some(isEmpty)) return;

  const blankOption = document.createElement('option');
  select.appendChild(blankOption);

  const records: Record[] = await loadFunction(...loadFunctionArgs);
  records.forEach((record) => {
    const option = document.createElement('option');
    if (record.id.toString() === selectedValue) {
      option.selected = true;
    }
    option.value = record.id.toString();
    option.text = record.inputLabel || record.name;
    select.appendChild(option);
  });
};

// eslint-disable-next-line import/prefer-default-export
export { loadSelect };
